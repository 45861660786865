$error-red: #E84037;
$warning: #E89A37;
$ok: #70CC00;

$light-gray: #F5F5F5;
$medium-gray: rgba(0, 0, 0, 0.12);
$dark-gray: rgba(0, 0, 0, 0.54);
$primary-green: #A8DA34;
$pink: #B52B82;
$blue: #2A6A94;
$orange: #E89A37;

$light-blue: #6C9FC0;

$background-color: $light-gray;
