// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #a8da34;
  --ion-color-primary-rgb: 168, 218, 52;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #699404;
  --ion-color-primary-tint: #d3f583;

  --ion-color-secondary: #2a6a94;
  --ion-color-secondary-rgb: 42, 106, 148;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #679300;
  --ion-color-secondary-tint: #14557f;

  --ion-color-tertiary: #b52b82;
  --ion-color-tertiary-rgb: 181, 43, 130;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #7b034e;
  --ion-color-tertiary-tint: #d974b3;

  --ion-color-success: #89bb14;
  --ion-color-success-rgb: 137, 187, 20;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #699404;
  --ion-color-success-tint: #c1ee59;

  --ion-color-warning: #B52B82;
  --ion-color-warning-rgb: 181, 43, 130;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #7B034E;
  --ion-color-warning-tint: #D974B3;

  --ion-color-danger: #E83C37;
  --ion-color-danger-rgb: 214, 11, 26;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #9E0804;
  --ion-color-danger-tint: #FF8B88;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
