/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import "theme/dragular.scss";

@import "assets/styles/brakepoints";
@import "assets/styles/layout";
@import "assets/styles/btns";
@import "assets/styles/colors";
@import "assets/styles/flex";

@font-face {
  font-family: 'Mont Heavy';
  src: url('assets/fonts/Mont-Heavy.otf') format('opentype');
}
@font-face {
  font-family: 'Mont ExtraLight';
  src: url('assets/fonts/Mont-ExtraLight.otf') format('opentype');
}
@font-face {
  font-family: 'MusticaPro';
  src: url('assets/fonts/MusticaPro-SemiBold.otf') format('opentype');
}
@font-face {
  font-family: 'Varela';
  src: url('assets/fonts/varela-round.regular.ttf') format('truetype');
}

.height-100 {
  height: 100%;
}

.min-full-height{
  min-height: 100%;
}

.width-100 {
  width: 100%;
}

form > ion-item {
  padding-right: 1em;
}

@media (min-width: $lg) {
  ion-list-header {
    font-size: 22px;
    font-weight: 700;
  }
}

p, ol, ul {
  max-width: 700px;
}

h1, h2, h3 {
  font-family: 'Varela', sans-serif;
}

h1 {
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid $dark-gray;
  padding-bottom: 0.5em;
}

.emph {
  font-weight: 700;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

p.error {
  color: $error-red;
  font-weight: bold;
}

.movable {
  cursor: move;
}

.padding {
  padding: 1em;
}

.padding-top {
  padding-top: 1em;
}
.padding-right {
  padding-right: 1em;
}
.padding-down,
.padding-bottom {
  padding-bottom: 1em;
}

.hidden {
  display: none;
}

.ion-page {
  // justify-content: start;

  :has(.container), :has(.full-container){
    // overflow-y: auto;
  }
}

.container, .full-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: column;
  // padding-bottom: 55px;
}

.full-container {
  height: calc(100% - 10px);
}

.container > * {
  max-width: 800px;
  width: 100%;
}

.no-edit {
  opacity: 0.7;
}

ion-checkbox {
  @media screen and (max-width: $sm){
    --size: 32px;
  }
}


.logo {
  font-family: 'Mont Heavy';
  color: #FF7400;
}

.divider {
  border-bottom: 1px solid $medium-gray;
}
